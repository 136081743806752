var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('b-form',[_c('b-card',[_c('b-card-title',[_c('h2',[_vm._v(_vm._s(_vm.$t("ProductForm.Publish_product")))])]),_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("ProductForm.Product_Name"))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.Product_Name')},model:{value:(_vm.createProductProfile.name),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "name", $$v)},expression:"createProductProfile.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Product belongs to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"owner_type"}},[_vm._v(_vm._s(_vm.$t("ProductForm.Product_belongs"))+" ")]),_c('v-select',{attrs:{"id":"State","options":_vm.$store.state.locale.locale == 'ar'
                      ? _vm.createProductProfile.OwnerTypeOptionArabic
                      : _vm.createProductProfile.OwnerTypeOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createProductProfile.selectedOwnerType),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "selectedOwnerType", $$v)},expression:"createProductProfile.selectedOwnerType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Production country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"Country"}},[_vm._v(_vm._s(_vm.$t("ProductForm.Production_country"))+" ")]),_c('v-select',{attrs:{"id":"Country","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.retreiveStates(_vm.createProductProfile.selectedCountry.value)}},model:{value:(_vm.createProductProfile.selectedCountry),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "selectedCountry", $$v)},expression:"createProductProfile.selectedCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Production state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"State"}},[_vm._v(_vm._s(_vm.$t("ProductForm.Production_state"))+" ")]),_c('v-select',{attrs:{"id":"State","options":_vm.statesOptions,"label":"text"},model:{value:(_vm.createProductProfile.selectedState),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "selectedState", $$v)},expression:"createProductProfile.selectedState"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Production city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"label-for":"City"}},[_vm._v(_vm._s(_vm.$t("ProductForm.Production_city"))+" ")]),_c('b-form-input',{attrs:{"id":"City","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.Production_city')},model:{value:(_vm.createProductProfile.city),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "city", $$v)},expression:"createProductProfile.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Brief overview","rules":("required|max:" + _vm.$maxHighlightsProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"label-for":"highlights"}},[_vm._v(_vm._s(_vm.$t("ProductForm.overview_your_product"))+" ")]),_c('b-form-textarea',{attrs:{"id":"highlights","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.overview_your_product'),"rows":"2","max-rows":"2","no-auto-shrink":""},model:{value:(_vm.createProductProfile.highlights),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "highlights", $$v)},expression:"createProductProfile.highlights"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Detailed producer description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"label-for":"Owner-Details"}},[_vm._v(_vm._s(_vm.$t("ProductForm.producer_description"))+" ")]),_c('b-form-textarea',{attrs:{"id":"Owner-Details","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.producer_description'),"rows":"3","max-rows":"3","no-auto-shrink":""},model:{value:(_vm.createProductProfile.OwnerDetails),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "OwnerDetails", $$v)},expression:"createProductProfile.OwnerDetails"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"label-for":"description"}},[_vm._v(_vm._s(_vm.$t("ProductForm.description_your_product"))+" ")]),_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.description_your_product'),"rows":"3","max-rows":"3","no-auto-shrink":""},model:{value:(_vm.createProductProfile.description),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "description", $$v)},expression:"createProductProfile.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industries","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"Industry"}},[_vm._v(_vm._s(_vm.$t("ProductForm.industries_belong"))+" ")]),_c('div',{attrs:{"id":"app"}},[_c('treeselect',{attrs:{"multiple":true,"placeholder":_vm.$t('common.Select'),"options":_vm.industries,"normalizer":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.normalizerArabic
                        : _vm.normalizer},on:{"input":_vm.handeTreeSelect},model:{value:(_vm.createProductProfile.industries),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "industries", $$v)},expression:"createProductProfile.industries"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"Category"}},[_vm._v(_vm._s(_vm.$t("ProductForm.needs_from_sharing"))+" ")]),_c('v-select',{attrs:{"id":"Category","options":_vm.categories,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.resetDataSaleType()}},model:{value:(_vm.createProductProfile.selectedCategory),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "selectedCategory", $$v)},expression:"createProductProfile.selectedCategory"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(
              _vm.createProductProfile.selectedCategory.value ==
              '62b40b86956d948f90a39418'
            )?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"sale type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"saleType"}},[_vm._v(_vm._s(_vm.$t("ProductForm.sell_your_product"))+" ")]),_c('v-select',{attrs:{"id":"saleType","options":_vm.$store.state.locale.locale == 'ar'
                      ? _vm.createProductProfile.SaleTypeOptionArabic
                      : _vm.createProductProfile.SaleTypeOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createProductProfile.selectedSaleType),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "selectedSaleType", $$v)},expression:"createProductProfile.selectedSaleType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1195152872)})],1):_vm._e(),(
              _vm.createProductProfile.selectedSaleType.value == '1' &&
              _vm.createProductProfile.selectedCategory.value ==
                '62b40b86956d948f90a39418'
            )?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":""}},[_vm._v(_vm._s(_vm.$t("ProductForm.Currency"))+" ")]),_c('v-select',{attrs:{"id":"","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createProductProfile.SelectedCurrency),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "SelectedCurrency", $$v)},expression:"createProductProfile.SelectedCurrency"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1017607592)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"Price"}},[_vm._v(_vm._s(_vm.$t("ProductForm.Price"))+" ")]),_c('b-form-input',{attrs:{"id":"Price","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.Your_Own_Price'),"type":"number"},model:{value:(_vm.createProductProfile.Price),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "Price", $$v)},expression:"createProductProfile.Price"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,675468049)})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"quantity","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"quantity"}},[_vm._v(_vm._s(_vm.$t("ProductForm.quantity"))+" ")]),_c('b-form-input',{attrs:{"id":"quantity","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.quantity'),"type":"number"},model:{value:(_vm.createProductProfile.quantity),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "quantity", $$v)},expression:"createProductProfile.quantity"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"sales numbers","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"sales_numbers"}},[_vm._v(_vm._s(_vm.$t("ProductForm.How_many_sold"))+" ")]),_c('b-form-input',{attrs:{"id":"sales_numbers","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.How_many_sold'),"type":"number"},model:{value:(_vm.createProductProfile.salesNumbers),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "salesNumbers", $$v)},expression:"createProductProfile.salesNumbers"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v(_vm._s(_vm.$t("ProductForm.expiration_date"))+" ")]),_c('validation-provider',{attrs:{"rules":"","name":"Expiry Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({staticClass:"mb-2",attrs:{"id":"example-datepicker","state":errors.length > 0 ? false : null,"min":_vm.createProductProfile.minDate,"locale":_vm.$store.state.locale.locale,"placeholder":_vm.$t('common.No_date_selected')},model:{value:(_vm.createProductProfile.expiryDate),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "expiryDate", $$v)},expression:"createProductProfile.expiryDate"}},'b-form-datepicker',
                  _vm.$store.state.locale.locale == 'ar' ? _vm.labels['ar'] : {}
                ,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Product Regestration","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{attrs:{"label-for":"regestration"}},[_vm._v(_vm._s(_vm.$t("ProductForm.registration_number"))+" ")]),_c('b-form-input',{attrs:{"id":"regestration","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ProductForm.registration_number')},model:{value:(_vm.createProductProfile.productRegistration),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "productRegistration", $$v)},expression:"createProductProfile.productRegistration"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"blockchain exchange","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"blockchain_exchange"}},[_vm._v(_vm._s(_vm.$t("ProductForm.blockchain_exchange"))+" ")]),_c('v-select',{attrs:{"id":"blockchain_exchange","options":_vm.$store.state.locale.locale == 'ar'
                      ? _vm.createProductProfile.ExchangeOptionArabic
                      : _vm.createProductProfile.ExchangeOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createProductProfile.selectedExchange),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "selectedExchange", $$v)},expression:"createProductProfile.selectedExchange"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProductForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.createProductProfile.facebookUrl),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "facebookUrl", $$v)},expression:"createProductProfile.facebookUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProductForm.Instagram'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://Instagram.com/abc"},model:{value:(_vm.createProductProfile.twitterUrl),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "twitterUrl", $$v)},expression:"createProductProfile.twitterUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProductForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.createProductProfile.linkedinUrl),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "linkedinUrl", $$v)},expression:"createProductProfile.linkedinUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ProductForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.createProductProfile.OtherUrl),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "OtherUrl", $$v)},expression:"createProductProfile.OtherUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"main image","rules":("required|size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"idea-icon"}},[_vm._v(_vm._s(_vm.$t("ProductForm.main_product")))]),_c('b-form-file',{attrs:{"id":"idea-icon","state":errors.length > 0 ? false : null,"accept":"image/jpeg, image/png, image/gif","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.createProductProfile.icon),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "icon", $$v)},expression:"createProductProfile.icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"product images","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"product-images"}},[_vm._v(_vm._s(_vm.$t("ProductForm.product_images")))]),_c('b-form-file',{attrs:{"id":"product-images","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here...","multiple":true},model:{value:(_vm.createProductProfile.images),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "images", $$v)},expression:"createProductProfile.images"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"product documents","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"product_documents"}},[_vm._v(_vm._s(_vm.$t("ProductForm.product_documents"))+" ")]),_c('b-form-file',{attrs:{"id":"product_documents","accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","required":"","state":errors.length > 0 ? false : null,"multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.createProductProfile.docouments),callback:function ($$v) {_vm.$set(_vm.createProductProfile, "docouments", $$v)},expression:"createProductProfile.docouments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.errorMessage)?_c('b-alert',{staticClass:"warning",staticStyle:{"color":"red"},attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('br'),_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("ProductForm.Submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }