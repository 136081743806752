<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form>
        <b-card>
          <b-card-title
            ><h2>{{ $t("ProductForm.Publish_product") }}</h2>
          </b-card-title>
          <br />
          <b-row>
            <!--  name-->
            <b-col md="6">
              <b-form-group>
                <label class="required"
                  >{{ $t("ProductForm.Product_Name") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="required"
                >
                  <b-form-input
                    v-model="createProductProfile.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.Product_Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Product belongs to"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="owner_type"
                    >{{ $t("ProductForm.Product_belongs") }}
                  </label>
                  <v-select
                    id="State"
                    v-model="createProductProfile.selectedOwnerType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createProductProfile.OwnerTypeOptionArabic
                        : createProductProfile.OwnerTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Production country"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="Country" class="required"
                    >{{ $t("ProductForm.Production_country") }}
                  </label>
                  <v-select
                    id="Country"
                    v-model="createProductProfile.selectedCountry"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="
                      retreiveStates(createProductProfile.selectedCountry.value)
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- <b-col md="6">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="createProductProfile.email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john.doe@email.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Production state"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="State" class="required"
                    >{{ $t("ProductForm.Production_state") }}
                  </label>

                  <v-select
                    id="State"
                    v-model="createProductProfile.selectedState"
                    :options="statesOptions"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Production city"
                  rules="required"
                >
                  <label label-for="City" class="required"
                    >{{ $t("ProductForm.Production_city") }}
                  </label>
                  <b-form-input
                    id="City"
                    v-model="createProductProfile.city"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.Production_city')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Brief overview"
                  :rules="`required|max:${$maxHighlightsProfile}`"
                >
                  <label label-for="highlights" class="required"
                    >{{ $t("ProductForm.overview_your_product") }}
                  </label>

                  <b-form-textarea
                    id="highlights"
                    v-model="createProductProfile.highlights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.overview_your_product')"
                    rows="2"
                    max-rows="2"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Detailed producer description"
                  rules="required"
                >
                  <label label-for="Owner-Details" class="required"
                    >{{ $t("ProductForm.producer_description") }}
                  </label>
                  <b-form-textarea
                    id="Owner-Details"
                    v-model="createProductProfile.OwnerDetails"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.producer_description')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <label label-for="description" class="required"
                    >{{ $t("ProductForm.description_your_product") }}
                  </label>
                  <b-form-textarea
                    id="description"
                    v-model="createProductProfile.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.description_your_product')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industries"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="Industry" class="required"
                    >{{ $t("ProductForm.industries_belong") }}
                  </label>

                  <div id="app">
                    <treeselect
                      v-model="createProductProfile.industries"
                      :multiple="true"
                      :placeholder="$t('common.Select')"
                      :options="industries"
                      @input="handeTreeSelect"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="Category" class="required"
                    >{{ $t("ProductForm.needs_from_sharing") }}
                  </label>
                  <v-select
                    id="Category"
                    v-model="createProductProfile.selectedCategory"
                    :options="categories"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="resetDataSaleType()"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              v-if="
                createProductProfile.selectedCategory.value ==
                '62b40b86956d948f90a39418'
              "
            >
              <validation-provider
                #default="{ errors }"
                name="sale type"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="saleType" class="required"
                    >{{ $t("ProductForm.sell_your_product") }}
                  </label>

                  <v-select
                    id="saleType"
                    v-model="createProductProfile.selectedSaleType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createProductProfile.SaleTypeOptionArabic
                        : createProductProfile.SaleTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="
                createProductProfile.selectedSaleType.value == '1' &&
                createProductProfile.selectedCategory.value ==
                  '62b40b86956d948f90a39418'
              "
              md="6"
            >
              <b-row>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="" class="required"
                        >{{ $t("ProductForm.Currency") }}
                      </label>

                      <v-select
                        id=""
                        v-model="createProductProfile.SelectedCurrency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Price"
                    rules="required|integer"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="Price" class="required"
                        >{{ $t("ProductForm.Price") }}
                      </label>
                      <b-form-input
                        id="Price"
                        v-model="createProductProfile.Price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.Your_Own_Price')"
                        type="number"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="quantity"
                rules="integer"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="quantity" class="required"
                    >{{ $t("ProductForm.quantity") }}
                  </label>

                  <b-form-input
                    id="quantity"
                    v-model="createProductProfile.quantity"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.quantity')"
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="sales numbers"
                rules="integer"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="sales_numbers" class="required"
                    >{{ $t("ProductForm.How_many_sold") }}
                  </label>

                  <b-form-input
                    id="sales_numbers"
                    v-model="createProductProfile.salesNumbers"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.How_many_sold')"
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <label for="example-datepicker" class=""
                >{{ $t("ProductForm.expiration_date") }}
              </label>
              <validation-provider
                #default="{ errors }"
                rules=""
                name="Expiry Date"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="createProductProfile.expiryDate"
                  :state="errors.length > 0 ? false : null"
                  class="mb-2"
                  :min="createProductProfile.minDate"
                  :locale="$store.state.locale.locale"
                  :placeholder="$t('common.No_date_selected')"
                  v-bind="
                    $store.state.locale.locale == 'ar' ? labels['ar'] : {}
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Product Regestration"
                rules=""
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="regestration"
                    >{{ $t("ProductForm.registration_number") }}
                  </label>
                  <b-form-input
                    id="regestration"
                    v-model="createProductProfile.productRegistration"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProductForm.registration_number')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="blockchain exchange"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label label-for="blockchain_exchange" class="required"
                    >{{ $t("ProductForm.blockchain_exchange") }}
                  </label>
                  <v-select
                    id="blockchain_exchange"
                    v-model="createProductProfile.selectedExchange"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createProductProfile.ExchangeOptionArabic
                        : createProductProfile.ExchangeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProductForm.Facebook')"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="url"
                >
                  <b-form-input
                    id="facebook"
                    v-model="createProductProfile.facebookUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProductForm.Instagram')"
                label-for="twitter"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Twitter"
                  rules="url"
                >
                  <b-form-input
                    id="twitter"
                    v-model="createProductProfile.twitterUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://Instagram.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProductForm.LinkedIn')"
                label-for="linked-in"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="url"
                >
                  <b-form-input
                    id="linked-in"
                    v-model="createProductProfile.linkedinUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProductForm.Website')"
                label-for="Other"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Other"
                  rules="url"
                >
                  <b-form-input
                    id="Other"
                    v-model="createProductProfile.OtherUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://other.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="main image"
                  :rules="`required|size:${$sizeFilesProfile}`"
                >
                  <label class="required" for="idea-icon">{{
                    $t("ProductForm.main_product")
                  }}</label>
                  <b-form-file
                    id="idea-icon"
                    v-model="createProductProfile.icon"
                    :state="errors.length > 0 ? false : null"
                    accept="image/jpeg, image/png, image/gif"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="product images"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label class="" for="product-images">{{
                    $t("ProductForm.product_images")
                  }}</label>
                  <b-form-file
                    id="product-images"
                    v-model="createProductProfile.images"
                    accept="image/jpeg, image/png, image/gif"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    :multiple="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="product documents"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label class="" for="product_documents"
                    >{{ $t("ProductForm.product_documents") }}
                  </label>
                  <b-form-file
                    id="product_documents"
                    v-model="createProductProfile.docouments"
                    accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    required
                    :state="errors.length > 0 ? false : null"
                    multiple
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
                >{{ errorMessage }}</b-alert
              >
              <br />
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t("ProductForm.Submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vue2Dropzone from "vue2-dropzone";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BPopover,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import {
  required,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    // VuePhoneNumberInput,
    ToastificationContent,
    BAlert,
    BOverlay,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const minDate = new Date(today);
    // maxDate.setMonth(maxDate.getDay() + 1);
    // maxDate.setDate(15);
    return {
      show: false,
      errorMessage: "",
      // isValidNumber: null,

      locales: [
        { value: "en", text: "English US (en-US)" },
        { value: "ar", text: "Arabic Egyptian (ar-EG)" },
      ],

      labels: {
        ar: {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "العقد السابق",
          labelPrevYear: "العام السابق",
          labelPrevMonth: "الشهر السابق",
          labelCurrentMonth: "الشهر الحالي",
          labelNextMonth: "الشهر المقبل",
          labelNextYear: "العام المقبل",
          labelNextDecade: "العقد القادم",
          labelToday: "اليوم",
          labelSelected: "التاريخ المحدد",
          labelNoDateSelected: "لم يتم اختيار تاريخ",
          labelCalendar: "التقويم",
          labelNav: "الملاحة التقويم",
          labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ",
        },
      },
      dropzoneOptionsIcon: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsDocouments: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      createProductProfile: {
        selectedState: null,
        name: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        selectedCountry: null,
        city: "",
        selectedIndustry: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        quantity: "",
        salesNumbers: "",
        expiryDate: "",
        productRegistration: "",
        minDate: minDate,
        icon: [],
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: "",
        // phoneCode: "",
        // email: "",
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],

        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],

        StatusOption: [
          { value: "1", text: "Complete" },
          { value: "2", text: "Incomplete" },
        ],
      },

      companyPopover: {
        EBITDA:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        MonthlySales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        AnnualSales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        CompanyImage:
          "Kindly upload your facility / office pictures as profiles with photos get higher rating and visibility. You can upload jpg, bmp, png, tiff, gif files which are less than 3MB in size.",
        CompanyDocuments:
          "Profiles with documents get higher rating and visibility. Documents uploaded are accessible only to members introduced to you. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif, mp4 files which are less than 3MB in size.",
        CompanyProof:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
        companyPhotos:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getProductCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      //createProduct: "profile/createProduct",
      createProduct: "profile/createProduct",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
    }),
    resetDataSaleType() {
      this.createProductProfile.selectedSaleType = "";
      this.createProductProfile.SelectedCurrency = "";
      this.createProductProfile.Price = "";
    },
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createProductProfile.industries.length = 6;
      }
    },
    // onUpdate(payload) {
    //   // console.log(payload);
    //   this.isValidNumber = payload.isValid;
    //   this.createProductProfile.phoneCode = payload.countryCode;
    // },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const ideaData = new FormData();
          ideaData.append("name", this.createProductProfile.name);
          ideaData.append(
            "typeofOwner",
            this.createProductProfile.selectedOwnerType.value
          );
          ideaData.append(
            "profileCountry",
            this.createProductProfile.selectedCountry.value
          );
          ideaData.append(
            "profileIndustries",
            JSON.stringify(this.createProductProfile.industries)
          );
          if (this.createProductProfile.facebookUrl) {
            ideaData.append("facebook", this.createProductProfile.facebookUrl);
          }
          if (this.createProductProfile.twitterUrl) {
            ideaData.append("twitter", this.createProductProfile.twitterUrl);
          }
          if (this.createProductProfile.linkedinUrl) {
            ideaData.append("linkedIn", this.createProductProfile.linkedinUrl);
          }
          if (this.createProductProfile.OtherUrl) {
            ideaData.append("otherURLS", this.createProductProfile.OtherUrl);
          }

          ideaData.append(
            "blockchainExchange",
            this.createProductProfile.selectedExchange.value
          );
          ideaData.append(
            "category",
            this.createProductProfile.selectedCategory.value
          );

          if (this.createProductProfile.selectedSaleType) {
            ideaData.append(
              "saleType",
              this.createProductProfile.selectedSaleType.value
            );
          }

          if (this.createProductProfile.SelectedCurrency) {
            ideaData.append(
              "currencyId",
              this.createProductProfile.SelectedCurrency.value
            );
          }
          if (this.createProductProfile.Price) {
            ideaData.append("priceNumber", this.createProductProfile.Price);
          }
          ideaData.append(
            "productQuantity",
            this.createProductProfile.quantity
          );
          ideaData.append(
            "productSales",
            this.createProductProfile.salesNumbers
          );

          if (this.createProductProfile.expiryDate) {
            ideaData.append(
              "productExpiryDate",
              this.createProductProfile.expiryDate
            );
          }
          if (this.createProductProfile.productRegistration) {
            ideaData.append(
              "productRegistration",
              this.createProductProfile.productRegistration
            );
          }

          //   ideaData.append(
          //     "ideaStatus",
          //     this.createProductProfile.selectedStatus.value
          //   );
          ideaData.append("description", this.createProductProfile.description);
          ideaData.append(
            "detailsofOwner",
            this.createProductProfile.OwnerDetails
          );
          ideaData.append("highlights", this.createProductProfile.highlights);
          ideaData.append("profileCity", this.createProductProfile.city);
          ideaData.append(
            "profileState",
            this.createProductProfile.selectedState.value
          );

          ideaData.append("profileIcon", this.createProductProfile.icon);

          if (this.createProductProfile.images) {
            for (const i of Object.keys(this.createProductProfile.images)) {
              ideaData.append(
                "profileImages",
                this.createProductProfile.images[i]
              );
            }
          }

          if (this.createProductProfile.docouments) {
            for (const i of Object.keys(this.createProductProfile.docouments)) {
              ideaData.append(
                "profileDocuments",
                this.createProductProfile.docouments[i]
              );
            }
          }

          ideaData.append("profileType", "product");
          // eslint-disable-next-line no-underscore-dangle
          ideaData.append("userId", this.user._id);
          this.createProduct(ideaData)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Added_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.show = false;
              this.$router.push({ name: "product-listAll" });
            })
            .catch((error) => {
              this.show = false;
              this.errorMessage = error.response.data.error.message;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
  },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
